import React from 'react';
import { ModalContextType, ModalType, useModalContext } from 'src/hooks/useModal';

import { EControlPositionType } from '../../../types/uniswapTokens';
import { ScrollModal } from '../../../components/primitives/ScrollModal';
import { PositionModalWrapper } from '../FlowCommons/PositionModalWrapper';
import { ClosePositionModalContent } from './ClosePositionModalContent';

export const ClosePositionModal = () => {
  const { type, args, close } = useModalContext() as ModalContextType<{
    positionAddress: string;
    controlType: EControlPositionType;
  }>;

  return (
    <ScrollModal open={type === ModalType.ClosePosition} setOpen={close}>
      <PositionModalWrapper
        positionAddress={args.positionAddress}
        title="Close position"
        modernStyle={true}
        controlType={args.controlType}
      >
        {(params) => <ClosePositionModalContent controlType={args.controlType} {...params} />}
      </PositionModalWrapper>
    </ScrollModal>
  );
};
